<template>
	<div class="claimblocs">

		<slot name="title"></slot>

		<template v-for="(bloc, index) in blocType" :key="index">

			<div class="claimor" v-if="blocType.length >= 2 && index !== 0">{{$t('pageLitteracy.registration.or')}}</div>

			<div class="claimbloc">

				<div v-if="bloc==='email'">
					<custom-input
					fieldname="email"
					:placeholder="$t('inputsTexts.email.placeholder')"
					inputType="text"
					:idlead="config.idlead"
					:label="$t('inputsTexts.email.label')"
					:helper="$t('inputsTexts.email.helper')"
					:isRequired="false"
					v-model.lazy:modelValue="email"
					ref="email"
					></custom-input>

					<button @click="askCreationParticipation('email');" :disabled="isLoading" tabindex="0">{{$t('pageLitteracy.registration.claimMail')}}</button>
				</div>


				<div v-if="bloc==='whatsapp'">
					<label for="nothing">{{$t('pageLitteracy.registration.whatsappTitle')}}</label>
					<p style="max-width:80%; margin: 0 auto 2em;">{{$t('pageLitteracy.registration.whatsappInfo.part1')}} <strong>{{getWhatsappCode()}}</strong> {{$t('pageLitteracy.registration.whatsappInfo.part2')}}</p>

					<a :href="getWhatsappLink()" class="fake_btn" target="_blank" rel="noopener" @click.capture="askCreationParticipation('whatsapp');" :class="{disabled:isLoading}" tabindex="0">
						{{$t('pageLitteracy.registration.claimWhatsapp')}}
					</a>
				</div>


				<div v-if="bloc==='sms'">
					<phone-input
					fieldname="phoneNumber1"
					v-model="smsHelper"
					inputType="phone"
					:idlead="config.idlead"
					:placeholder="$t('inputsTexts.phone.placeholder')"
					:label="$t('inputsTexts.phone.label')"
					:helper="$t('inputsTexts.phone.helper')"
					:isRequired="false"
					@internationalNumber="setPhone"
					ref="phoneNumber1"
					></phone-input>

					<button @click="askCreationParticipation('phoneNumber1');" :disabled="isLoading" tabindex="0">{{$t('pageLitteracy.registration.claimSms')}}</button>

				</div>

			</div>
		</template>
	</div>
</template>

<!-- ================================================================================== -->

<script>
	import customLog from "../mixins/CustomLog";
	import CustomInput from "@/components/CustomInput";
	import config from "../config";
	import PhoneInput from "../components/PhoneInput";
	import 'intl-tel-input/build/css/intlTelInput.css';

	export default {
		name:"ClaimBlocs",

		components: {
			CustomInput,
			PhoneInput,
		},

		mixins: [customLog],

		props: {
			blocType : {
				type: [Array],
			},
			lang : {
				type: String,
				required: false
			},
			whatsappcodes : {
				type: String,
			}

		},
		data(){
			return {
				email : '',
				smsHelper: '',
				sms: '',
				whatsapp: '',
				choosenMean : '',
				isLoading : false,
				config: config,

				isThereMessage: false,
				messageContent: [],
				messageType: "alert",
			}
		},

		methods : {
			setPhone(number) {
				this.sms = number;
			},
			
			leaveThisPage(){
				this.log(`I leave the registration page and I go to confirmRegistration`, 'low');
				this.$emit('goto', 'confirmRegistration');
			},

			check(fieldname){
				let el = document.getElementById(fieldname);
				if(el.classList.contains('hasSuccess')){
					this.log(`Field ${fieldname} has class Success`, 'low');
					return true;
				}else{
					this.log(`Field ${fieldname} is empty or in error`, 'low');
					this.$refs[fieldname].validateMyself();
					return false;
				}
			},

			askCreationParticipation(fieldname) {
				/* changing the button status */
				this.isLoading = true;

				this.log("CreateParticipationOwner", 'group');
				this.log("I've just asked to create my participation ownership…", 'low');
				if(fieldname === "phoneNumber1" && this.check(fieldname)){
					this.log("…by phone", 'low');
					this.axiosPart(this.sms, fieldname);
					this.choosenMean = 'phone';
				}
				else if(fieldname === "email" && this.check(fieldname)){
					this.log("…by email", 'low');
					this.axiosPart(this.email, fieldname);
					this.choosenMean = 'email';
				}
				else if(fieldname === "whatsapp"){
					this.log("…by whatsapp", 'low');
					this.choosenMean = 'whatsapp';
					this.leaveThisPage();
				}else{
					this.isLoading = false;
				}
				this.log('', 'groupEnd');
			},

			getWhatsappCode(){
				return this.whatsappcodes;
			},

			getWhatsappLink(){
				return 'https://wa.me/' + config.whatsappNumber + '?text=' + this.getWhatsappCode();
			},

			getIdLanguage(){
				let id = 2;
				if (this.$i18n.locale.slice(0,2).toUpperCase() === 'FR'){
					id = 1;
				}else if (this.$i18n.locale.slice(0,2).toUpperCase() === 'NL'){
					id = 2;
				}
				this.log(`Claimblock determine that for value ${this.$i18n.locale.slice(0,2)} the idLanguage is ${id}`, 'low');
				return id;
			},


			gatherData(wayToContact,fieldname){
				return {
					"idLeadManagement": config.idlead,
					"isolanguagecode": this.$i18n.locale.slice(0,2),
					[fieldname] : wayToContact,
					"idLanguageCode" : this.getIdLanguage(),
				};
			},


			axiosPart(wayToContact, fieldname) {
				// set the loading state for button (to be created)
				this.isLoading = true;

				let data = this.gatherData(wayToContact,fieldname);
				this.log(`Data collected for the creation of ParticipationOwner`, 'low');
				this.log(data);
				this.axios.post(
					`/participationOwner`,
					data
					)
				.then(res => {
					this.log("I managed to create the participationOwner!", 'success');
					this.participationOwnerCreated(res);
				})
				.catch((error) => {
					this.log("I'm sorry Dave, I'm afraid I can't do that, I can't create the participationOwner", 'alert');
					this.participationOwnerReportError(error);
				});
			},

			participationOwnerCreated(res){
				this.log(res);
				this.$emit('mean', this.choosenMean);
				this.leaveThisPage();
			},

			participationOwnerReportError(error){
				/* The error could have a notification or not. It would be a keyed error, or not. */
				this.log('', 'sep');
				this.log(error);
				this.log('', 'sep');
				this.log(error.message);
				this.log(error.response?.data);

				this.log(error.response?.data?.message); // "The given data was invalid"
				let typeOfError = error.response?.data?.message;
				this.log(error.response?.data?.notifications); // ["U heeft al deelgenomen met deze gegevens"]
				let errorMessages = error.response?.data?.notifications;

				this.$emit('errorpresent', {error:typeOfError, def: errorMessages});
				this.anErrorOccured(error.response.data);
				this.isLoading = false;
			},

			anErrorOccured(error){
				this.log("An error occured", 'alert');
				this.log(error);
				let arrayMessage = [];
				/* if "notifications" key is present */

				if(error.notifications?.length > 0){
					this.log("There is notifications", 'low');
					this.displayMessage(error.notifications, 'alert');

				}else if(error.message?.length > 0){
					this.log("No notifications? Display message", 'low');
					/* if "message" exist, displaying it instead */
					arrayMessage.push(error.message);
					this.displayMessage(arrayMessage, 'alert');
					
				}else{
					/* just the message (in case of error 500) */
					this.log("Neither notifications nor messages?", 'low');
					arrayMessage.push(error);
					this.displayMessage(arrayMessage, 'alert');
				}
				/* reactivate buttons to allow re-submission */
				this.isClaimCashbackButtonActive = true;
			},


			displayMessage(message, mode) {
				this.log('displayMessage', "function");
				this.log(`Displaying message: ${message}`, 'low');
				this.isThereMessage = true;
				this.messageContent = message;
				this.messageType = mode;
			},

			hideMessage() {
				this.isThereMessage = false;
			},
		}
	}

</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
