/* Functions.js */
import config from "../config";

export default {
	data() {
		return {			
			oauth: {
				token_type:'',
				access_token:'',
				expires_in:''
			},
			registrationMean : '',
			lead : {	},
			idLead : config.idlead,
			config: config,
			currentPage : "loading",
			ownerKeyFromUrl: '',
			participationKeyFromUrl: '',
			isMailFieldDisabled: false,
			isPhoneFieldDisabled: false,
			/* Markers, to know if everything is received from the API at first load */
			leadDataAreReceived: false,
			localizingDataAreReceived: false,
			localizingData: null,
			metaDataAreReceived: false,
			allowFormToBeDisplayed: false,
			unlockFreeRetailersIDs: [],
			couponsDetails : [],
			numberOfCouponsInThisAction: 0,
		}
	},
	computed: {
		actionStartDate : function(){
			return this.getFullDate(this.actionFullDates.registration.start);
		},
		actionEndDate : function(){
			return this.getFullDate(this.actionFullDates.registration.end);
		},
	},

	methods: {

		/* DISPLAY AVAILABLE PARTICIPATION AND REGISTRATION */
		/* For console.INFO only */
		displayAvailableParticipations(){
			this.log("Available registrations and participations", "sep");
			console.group("Available registrations and participations");
			console.log("There are %c" + this.lead.inscriptions_left + "%c available registrations","font-weight:bold;","font-weight:normal;");
			console.log("There are currently %c" + this.lead.inscriptions_total + "%c registration(s) made","font-weight:bold;","font-weight:normal;");
			console.log("There are currently %c" + this.lead.participations_total + "%c participation(s) received","font-weight:bold;","font-weight:normal;");
			console.log("There are still %c" + this.lead.participations_left + "%c available participations","font-weight:bold;","font-weight:normal;");
			console.groupEnd();
		},

		/* CHECK IF MAINTENANCE IS ON */
		/* AxiosBuilder takes care of this.$store.getters.getMaintenance if VUE_APP_ALLOWMAINTENANCECHECK is true in environment file */
		/* But maintenance can be forced by environment var in case of emergency */
		checkMaintenance() {
			if (config.forceMaintenanceInCaseOfProblem){
				console.log("Maintenance in progress…");
				this.currentPage = 'maintenance';
				this.allowFormToBeDisplayed = true; // Required, as the API-Calls never happend
			}else{
				/* If not forced, see if I received a status info from servers */
				let status = this.$store.getters.getMaintenance; 
				/* status:false = no maintenance */

				if (!status) {
					this.log("No maintenance in progress", "info"); 
					this.startAction();
				} 
				if(status) {
					this.log("Maintenance in progress", "alert"); 
					this.currentPage = 'maintenance';
					this.allowFormToBeDisplayed = true; // Required, as the API-Calls never happend
					this.log(`=> Redirection to the ${this.currentPage} page`, "low");
				}
			}
		},
		

		/* START OF THE ACTION */
		startAction() {
			this.getFromAxios(this.callConfigs.getLocalizedData);
			this.getFromAxios(this.callConfigs.getCountries);
			this.getFromAxios(this.callConfigs.getLead);
			this.getFromAxios(this.callConfigs.getSynonyms);
			this.getFromAxios(this.callConfigs.getCouponsDetails);
			this.getParticipationTypeReference();
		},


		/* ALLOW USER IF HE HAS NOT PARTICIPATED */
		checkIfAlreadyParticipated () {
			if (this.ownerData.status !== "30"){
				this.noParticipationYet();
			}else{
				this.participationAlreadySent();
			}
		},
		noParticipationYet(){
			this.log("Participation has not been sent yet.", 'low');
			this.checkParticipationDates();
		},
		participationAlreadySent(){
			this.log("Participation already sent and this is not a reparticipation.", 'low');
			this.goto('participated');
		},

		/* USER HAS ALREADY SOME DATA THAT COULD GO INTO FIELDS AT LOADING */
		putDataInFields(){
			this.log("(Re)participation -> putting data into fields", 'group');
			if(this.reparticipation){
				this.log ("This is a reparticipation", 'info');
				this.putDataIntoFieldsForAReparticipation();
			} else {
				this.log("This is no reparticipation", 'info');
				/* INFO I could get these data from the key, when I connect from the participationLink */
				this.IfMailPutMailInMailFieldAndDisable();
				this.ifPhonePutPhoneInPhoneFieldAndDisable();
			}
			this.log('', 'groupEnd');
		},

		/* IF MAIL DATA IS PRESENT, PUT IT IN THE FORM */
		IfMailPutMailInMailFieldAndDisable(){
			this.log(`Filling mail field with: ${this.ownerData.email} and locking it.`, 'low');
			if(this.ownerData.email){
				this.fieldsValues.email = this.ownerData.email;
				this.isMailFieldDisabled = true;
			}
		},

		/* IF PHONE DATA IS PRESENT, PUT IT IN THE FORM */
		ifPhonePutPhoneInPhoneFieldAndDisable(){
			this.log(`Filling phone field with: ${this.ownerData.phonenumber1} and locking it.`, 'low');
			if(this.ownerData.phonenumber1){
				this.fieldsValues.phone1 = this.ownerData.phonenumber1;
				this.isPhoneFieldDisabled = true;
			}
		},

		justBeforeLoadingTheFormPage(){
			/* Here, one can perform a last-operation before going to the form page */
			this.goto(this.firstPageOfThisForm);
		},

		/* GET OWNER DATA FROM KEY */
		checkKey(){

			if(this.participationKeyFromUrl){
				this.getFromAxios(this.callConfigs.getParticipationOwnerData, this.participationKeyFromUrl);
			}else if(this.ownerKeyFromUrl){	
				this.getFromAxios(this.callConfigs.getOwnerData, this.ownerKeyFromUrl);
			}else{
				this.noOwnerDataToGet();
			}
		},

		/* MAX REGISTRATION/PARTICIPATION LEFT */
		isMaxRegistrationReached(){
			return this.lead.inscriptions_left <= 0;
		},
		isMaxParticipationReached(){
			return this.lead.participations_left <= 0;
		},

		/* REGISTRATION REQUIRED => CHECK REGISTRATION AVAILABILITY */
		whatToDoWhenRegistrationIsRequired(){
			if(this.isMaxRegistrationReached()){
				this.log("Max registration is reached: no new registration possible", 'info');
				this.goto('maxReached');
			}else{
				this.checkRegistrationDates();
			}
		},

		/* REGISTRATION NOT REQUIRED => CHECK PARTICIPATION AVAILABILITY */
		whatToDoWhenRegistrationIsNotRequired(){
			if(this.isMaxParticipationReached()){
				this.log("Max participation is reached: no new participation possible", 'info');
				this.goto('maxReached');
			}else{
				this.checkParticipationDates();
			}
		},






		checkActionStatusForAcceptance(tos){
			if(tos === "W"){ this.log(`The status need to be at least "O" for the action to be available for construction in acceptance.`, "alert"); }
			if(tos === "O"){ this.log(`The action is ready to be constructed in acceptance.`, "green"); }
			if(tos === "X"){ this.log(`The construction is possible.`, "green"); }
			if(tos === "T"){ this.log(`The action is tested by the client.`, "green"); }
			if(tos === "P"){ this.log(`The action is "live" in acceptance.`, "green"); }
			if (tos === 'C' || tos === 'A' || tos === 'R'){ this.log(`The action has been closed (C), cancelled (A) or refused (R) in acceptance.`, "red"); }
			console.log("-".repeat(60));
			/* In acceptance, we don't take status into account to continue to load the form… */
			this.continueWithTheChekIfAccessPossible();
		},

		checkActionStatusForProduction(tos){
			if(tos === "P"){
				console.log("Offer has 'P' status, action will be/is available for users.");
				console.log("-".repeat(60));
				this.continueWithTheChekIfAccessPossible();
			}
			if (tos === 'C' || tos === 'A' || tos === 'R'){
				/* The action is closed (C), canceled (A) or refused (R) */
				console.log("This action is closed.");
				this.goto("actionEnded");
			}
			if(tos === 'W' || tos === 'O' || tos === 'X' || tos === 'T'){
				/* Action should begin (dates are ok), but status is not right yet */
				console.log("This action is not yet in production, it is still waiting to receive the 'P' status.");
				this.goto("beforeStart");
			}	
		},

		/* CHECK IF USER CAN ACCESS THE FORM FOLLOWING CURRENT DATE AND ACTION STATUS */
		checkIfAccessToFormIsPossible() {
			let tos = this.lead.offerstatus.toUpperCase();
			/* INFO: status PRE : W, O, X, T */
			/* INFO: status POST : C, A, R */
			console.log("-".repeat(60));
			
			/* ONLY IN PRODUCTION BUILD */
			if (config.takeOfferStatusIntoAccount){
				this.checkActionStatusForProduction(tos);
			}

			/* FOR LOCALHOST OR ACCEPTANCE BUILDS */
			if (!config.takeOfferStatusIntoAccount){
				this.log(`The action status in L.M. ACCEPTANCE is set to ${tos}.`, "info");
				this.checkActionStatusForAcceptance(tos);
			}
		},

		getUniqueCoupon(){
			return { idLeadCoupon: this.couponsDetails[0].id, quantity: 1 };
		},

		getRequestedCouponsList(){
			let listOfCoupons = [];
			/* There is ONE or MORE coupons (if none, getRequestedCouponsList() is not reached) */

			/* There is exacly ONE coupon */
			if(this.numberOfCouponsInThisAction === 1){

				// There is no survey OR there is a survey and it was not skipped => add the details of ONE unique coupon
				if (!this.thereIsASurveyInThisAction || (this.thereIsASurveyInThisAction && !this.skipButtonIsClicked)){
					listOfCoupons.push(this.getUniqueCoupon());
				}
				// There is a survey but it was skipped => no coupon details to add
				
			}

			// There is MORE than one coupon !
			if(this.numberOfCouponsInThisAction >= 1){
				this.log("There is many coupons available, add one or multiple selected coupons to the list", 'low');
				// TODO there is no code yet to get a selection of coupons.
				this.log("No code for getRequestedCouponsList() in case user can select between many coupons",'todo');
				// TODO push them in the listOfCoupons array
			}
			return listOfCoupons;
		},

		isThereAvailableParticipationsRemaining(){
			return this.lead.participations_left > 0;
		},

		continueWithTheChekIfAccessPossible(){
			if (this.isUserThereTooSoon()){
				this.log('The action has not started yet. No entry.', 'info');
				this.goto('beforeStart');
			}
			else if (this.isUserThereTooLate()){

				if(this.isUserAbleToReparticipate()){
					this.log('The action is closed. But reparticipations are still open.', 'info');	
					this.identifyOwnerByKey();
				}else{
					this.log('The action is closed. No entry.', 'info');
					this.goto('actionEnded');
				}

			}
			else if (!this.isUserThereTooSoon() && !this.isUserThereTooLate()){
				this.log('The action is open. User can enter.', 'success');
				this.identifyOwnerByKey(); /* Registration required or not, this is still needed for reparticipation. */
			}
			else {
				/* ! No page here for this case yet */
			}
		},

		/* FROM THE LOCALE, DEDUCE THE CURRENT COUNTRY ID */
		getCountryIdFromLocale(){
			let idCountryCode = this.$i18n.locale.slice(-2);
			let codes = ['', 'BE', 'FR', 'NL', 'LU', 'DE'];
			this.log(`I return ${codes.indexOf(idCountryCode)} as index of idCountryCode in codes`, 'low');
			return codes.indexOf(idCountryCode);
		},

		/* NO KEY IN THE URL, CHECK IF IT IS STILL POSSIBLE TO PARTICIPATE (maxReached) */
		noOwnerDataToGet(){
			if (!this.isThereAvailableParticipationsRemaining()){
				this.log('Max participation reached', 'info');
				this.currentPage = 'maxReached';
			}else{
				this.checkAndRedirectIfRegistrationRequired();
			}
		},

		/* CHECK IF USER MUST REGISTER HIMSELF */
		checkAndRedirectIfRegistrationRequired(){
			if(this.registrationRequired){
				this.log("As registration is required, checking availability", 'low');
				this.whatToDoWhenRegistrationIsRequired();
			}
			if(!this.registrationRequired){
				this.log("Registration is not required", 'info');
				this.whatToDoWhenRegistrationIsNotRequired();
			}
		},


		/* PUT THE PHONE IN THE FORM */
		setPhone(value) {
			this.log("I set the phone value to: " + value, 'low');
			this.fieldsValues.phone1 = value;
		},

		/* PUT THE EMAIL IN THE FORM */
		setMail(value) {
			this.log("I set the mail value to: " + value, 'low');
			this.fieldsValues.email = value;
		},

		/* MOVE FROM ONE PAGE TO ANOTHER */
		goto(dest){
			this.log(`I received ${dest} as a target destination`, 'low');

			if (this.thereIsFloodLightScriptInThisAction){
				this.insertFloodLightScripts(dest);
			}

			/* add robot for non-indexing technical pages */
			this.checkForNonIndexablesPagesForThis(dest);
			this.logCustomEvent(dest); // for pixel.js
			this.currentPage = dest;
			this.instantBackToTop();
		},

		instantBackToTop(){
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},

		/* FLOODLIGHT */
		insertFloodLightScripts(dest) {
			if (dest != 'confirmation'){
				this.createEventScriptForLandingPage();
				this.createGlobalScriptForLandingPage();
			}
			if (dest === 'confirmation'){
				this.createEventScriptForThanksPage();
				this.createGlobalScriptForThanksPage();
			}
		},

		/* USER IS READY FOR HIS CASHBACK */
		userWantToClaimHisCashback(){
			this.log("userWantToClaimHisCashback", "function");
			this.log("User is ready and click to ask his cashback", 'sep');
			/* Check to avoid cheat by removing the "disables" attribute on button */
			if(this.isThereARequiredFieldMissing()){
				this.log("Some fields are missing", 'alert');
				this.displayMessage(this.$t('errors.fieldsMissingTitle'), [this.$t('errors.fieldsMissing')], 'alert')
			}
			if(!this.isThereARequiredFieldMissing()){
				this.continueToProcessTheForm();
			}
		},

		/* IS THERE A SURVEY? */
		continueToProcessTheForm(){
			if(!this.thereIsASurveyInThisAction){
				this.log("As there is no survey, I skip this step", 'low');
				this.skipSurvey();
			}	
			if(this.thereIsASurveyInThisAction){
				this.log("As there IS a survey, I go to the page that offer the choice to answer or not", 'low');
				this.goto('choiceSurvey');
			}	
		},

		getUrlParamsHTML(){
			let html = "<ul>";
			let urlParams = new URLSearchParams(window.location.search);
			if (urlParams.has('key')){
				let unsafeKey = urlParams.get('key');
				let safeKey = encodeURIComponent(unsafeKey);
				html += `<li><b>Key:</b> ${safeKey}</li>`;
			}
			if (urlParams.has('participationKey')){
				let unsafePartKey = urlParams.get('participationKey');
				let safePartKey = encodeURIComponent(unsafePartKey);
				html += `<li><b>Participation Key:</b> ${safePartKey}</li>`;
			}
			html += "</ul>";
			return html;
		},

		/* GET AND RETURNS SOME KEYS FOUND IN THE URL */
		getKeysFromUrl(){

			this.log('Looking in the URL for keys','low');
			let urlParams = new URLSearchParams(window.location.search);

			/* In case of reparticipation, there SHOULD be a participationKey */
			if (urlParams.has('participationKey')){
				this.participationKeyFromUrl = urlParams.get('participationKey');
				this.log(`-> Found a participationKey: ${this.participationKeyFromUrl}`,'low');
			}else{
				this.log("-> No participation key found", 'low');
			}
			/* In case of participation, there COULD be a key */
			if (urlParams.has('key')){
				this.ownerKeyFromUrl = urlParams.get('key');
				this.log(`-> Found an owner key: ${this.ownerKeyFromUrl}`,'low');
			}else{
				this.log("-> No owner key found", 'low');
			}
		},

		/* CLAIMBLOCK TELLS WHAT USER WANT FOR REGISTRATION */
		setRegistrationMean(mean){
			this.registrationMean = mean;
		},

		/* IF LOCALE CHANGE, WHATSAPP CODE MUST BE REFRESHED */
		updateWhatsappCodes(){
			this.log("Getting codes for Whatsapp", 'low');
			try {
				let curloc = this.$i18n.locale.slice(0,2).toLowerCase();
				this.currentCodes = this.lead.registrationkeycode[curloc].trim();
			}catch(e){
				this.log(e, "alert");
			}
		},

		/* GET KEY AND FIND USER */
		identifyOwnerByKey () {
			this.getKeysFromUrl();
			this.checkKey();
		},

		/* IF THERE IS A SURVEY, SKIP IT */
		skipSurvey(){
			this.log("I skip the survey", 'low');
			this.surveyMustBeFilled = false;
			this.skipButtonIsClicked = true;
			this.log("I read the checklist", 'low');
			this.checkListBeforeSending();
		},

		/* I GOT OWNERDATA, NOW I USE THEM */
		setOwnerData(data){
			if(data.reparticipation) {
				this.ownerData = data["ParticipationOwner"];
				this.bigOwnerData = data;
				this.log("I found this IS a reparticipation", 'low');
				this.reparticipation = true;
			} else {
				this.log("I found this is NOT a reparticipation", 'low');
				this.ownerData = data;
			}
		},

		/* OWNER DATA IN CONSOLE */
		displayOwnerDataInConsole(){
			this.log("ownersData : participation, reparticipation", 'group')
			this.log(this.ownerData);
			this.log(this.bigOwnerData);
			this.log('', 'groupEnd');
		},

		/* SETTING LANG */
		/* (triggered by the participationOwner data callback) */
		setLang (id) {
			/* Updates i18n and store */
			let langs = ['en', 'fr', 'nl'];
			this.log(`Change lang to: ${langs[id]}`, 'low');
			let targetLocale = 'nl_BE';
			if (id == '1'){
				targetLocale = 'fr_BE';
			}else{
				targetLocale = 'nl_BE';
			}
			this.$i18n.locale = targetLocale;
			this.log(`The i18n Locale is now: ${this.$i18n.locale}`,'low');
			this.$store.dispatch('setIdLanguageCode', {id:id}); // If for a reason user change his speaking language
			this.$store.dispatch('setLocale', {locale: targetLocale});
			this.log(`The STORE locale is now ${this.$store.getters.getLocale}`, 'low');
		},

		/* CHECK this.fieldsValues.popitems, AND RETURN THOSE THAT CONTAINS A VALUE */
		returnsOnlyNonEmptyPopitems(){
			let list = [];
			for (let item in this.fieldsValues.popitems){
				if (this.fieldsValues.popitems[item]){
					list.push({"idPOPItem": this.fieldsValues.popitems[item], "quantity": 1});
				}
			}
			return list;
		},

		/* BLOCK BUTTONS AND CHECK IF PARTICIPATION COULD BE SENT */
		sendParticipation(){
			this.deactiveAllButtons();
			if(this.areConditionsToSendTheParticipationMet()){
				this.conditionsForParticipationAreMet();
			}else{
				this.conditionsForParticipationAreNotMet();
			}
		},
		conditionsForParticipationAreMet(){
			this.log(`Conditions to send the participation are OK`, 'green');
			this.log("Before posting, what did I received from composeData() ?");
			this.composeData();
			this.log(this.composedDataForParticipation);
			this.postToAxios(this.callConfigs.postParticipation, this.composedDataForParticipation);
		},
		conditionsForParticipationAreNotMet(){
			this.log(`Conditions to send the participation are not OK`, 'red');
			this.activeAllButtons();
			this.displayMessage('Error', [this.$t('errors.fieldMissingValue')], 'alert');
			this.isClaimCashbackButtonActive = true;
			this.goto('form1');
		},

		convertCountryCodeToId(code) {
			this.log("convertCountryCodeToId", "function");
			this.log(`Received country code: ${code}`, "low");
			let theCorrespondingCountryIdsAre = this.listOfAllowedCountries.filter(country => country.code === code);
			this.log("Filtered countries: ", "low");
			this.log(theCorrespondingCountryIdsAre);
			return theCorrespondingCountryIdsAre[0].id;
		},

		convertCountryIdToDescription(id){
			this.log("convertCountryIdToDescription", "function");
			this.log(`Received id ${id} to convert to country description`, "wip");
			let theCorrespondingCountryDescriptionsAre = this.listOfAllowedCountries.filter(country => country.id === id);
			this.log("Filtered countries: ", "low");
			this.log(theCorrespondingCountryDescriptionsAre);
			return theCorrespondingCountryDescriptionsAre[0].description;
		},

		convertCountryCodeToDescription(code){
			this.log("convertCountryCodeToLocaleDescription", "function");
			this.log("Received country code: " + code, "low");
			let theCorrespondingCountryDescriptionsAre = this.listOfAllowedCountries.filter(country => country.code === code);
			this.log("Filtered countries: ", "low");
			this.log(theCorrespondingCountryDescriptionsAre);
			return theCorrespondingCountryDescriptionsAre[0].description;

		},


		toggleTestPanelVisibility(){
			this.fullTestPanelHidden = !this.fullTestPanelHidden;
		},

		checkListBeforeSending() {
			this.log("checkListBeforeSending", 'function');
			this.log("I read the item on the checklist", 'low');
			this.pendingParticipationSent = true;
			this.log(`I'm running the process to send the participation: ${this.pendingParticipationSent}`, 'low');
			if( !this.registrationRequired ){
				this.log("As registration is not required, I must first create the participationOwner before trying to send the participation", 'info');
				this.createParticipationOwner();
			}
			if( this.registrationRequired ){
				this.log("As registration is required beforehand, participationOwner exist, and I have already loaded it's data", 'info');
				this.sendParticipation();
			}
		},

		/* WHEN REPARTICIPATION, METHODS TO PUT SPECIFIC DATA INTO FIELDS */
		treatParticipationMetaData(category){
			/* la première reçue, avec idleadtmdv === 1 correspond à l'enquête, obligatoire ou non */
			/* La seconde seulement est une data utilisateur */
			this.bigOwnerData[category].forEach((item, index) => {
				if(item.idleadtmd !== 1){
					this.log(item);
					this.fieldsValues.survey[`answersSQ${index+1}`] = item.idleadtmdv;
					this.fieldsValues.survey[`answersSQ${index+1}alt`] = item.answer;
					this.log(this.fieldsValues.survey);
				}
			});
		},

		checkIfMaxParticipationReached(){
			if( this.isMaxParticipationReached() ){
				this.goto('maxReached');
			}
			if ( !this.isMaxParticipationReached() ){
				this.checkIfAlreadyParticipated();
			}
		},

		treatProofOfPurchaseHeaderConsumer(category){
			this.log("treatProofOfPurchaseHeaderConsumer", 'group');
			this.log(this.bigOwnerData[category].idpopretailer);
			this.fieldsValues.retailer = this.bigOwnerData[category].idpopretailer;
			this.fieldsValues.freeRetailer = this.bigOwnerData[category].freeretailername;
			this.fieldsValues.purchaseDate = this.bigOwnerData[category].purchasedate;
			this.log(this.bigOwnerData[category].freeretailername);
			this.log(this.bigOwnerData[category].purchasedate);
			this.log('', 'groupEnd');
		},

		treatParticipationOwnerData(category){
			let keys = Object.keys(this.bigOwnerData[category]);
			this.log(keys, 'low');
			if(typeof this.bigOwnerData[category] === 'object'){
				keys.forEach((key) => {
					this.log(this.bigOwnerData[category][key]);
					this.fieldsValues[key] = this.bigOwnerData[category][key];
				});
				this.log(this.fieldsValues);
			}
		},



		/*
		GENERAL USE TOOLS
		
		*/

		/* Remove keys with null values from objects */
		cleanNullValues(object){
			this.log("Cleaning object from null keys", 'low');
			Object.keys(object).forEach(key => {
				if (object[key] === null || object[key] === undefined || object[key] === '') {
					this.log(`Cleaning key ${key} for emptyness. Value was ${object[key]}`, "low");
					delete object[key];
				}
			});
			return object;
		},

		consoleDateOfBuild(){
			let buildDate = document.documentElement.dataset.buildTimestampUtc;
			let trueDate = new Date(buildDate);
			let year = trueDate.getFullYear();
			let month = trueDate.getMonth()+1;
			let dt = trueDate.getDate();
			console.log(`Date of build: %c${dt}/${month}/${year}`,"font-weight:bold; color: #00c;");
		},

		checkUrlForTestPanel(){
			let urlParams = new URLSearchParams(window.location.search);
			if(urlParams.has('fulltest')){
				if(urlParams.get('fulltest')==='on'){
					this.fullTestPanelIsRequested = true;
					this.log(`FULL TEST mode activated`, 'title');
				}
			}

		},

		/* UNKEY KEYED ERRORS */
		formatKeyedErrorForDisplay(obj){
			/* Get every Key values (String or Array) and put them into an array */
			let result = [];
			for (let key in obj) {
				let value = obj[key];

				if (typeof value === "string") {
					result.push(value);
				}
				if (Array.isArray(value)) {
					for (let i = 0; i < value.length; i++) {
						if (typeof value[i] === "string") {
							result.push(value[i]);
						}
					}
				}
			}
			return result;
		},




		/*
		API CALLBACKS (and the function calling if available)
		
		*/

		/* ++++ API CALL: GET PARTICIPATION TYPE REFERENCE ++++ */
		cb_participationTypeRefFound(data){
			this.participRefs = data;
			this.log("Refs stored in participRefs", 'low');
		},
		/* ---- API CALL: GET PARTICIPATION TYPE REFERENCE ---- */
		cb_participationTypeRefNotFound(err){
			this.log(err);
		},
		/* ==== API CALL: GET PARTICIPATION TYPE REFERENCE ==== */
		getParticipationTypeReference(){
			this.getFromAxios(this.callConfigs.getParticipationTypeRef);
		},


		// At statup form I call the API "getSynonyms"
		whatIfIGetTheSynonymsList(data){
			this.synonyms = data;
			this.log(`Synonyms stored in "synonyms"`, 'info');
		},
		whatIfICantGetTheSynonymsList(err){
			this.log("APICallbacks > whatIfICantGetTheSynonymsList", "error");
			this.log(err);
		},


		/* SUCCESS FOR "POST PARTICIPATION" API CALL */
		participationSuccessful(res){
			this.log("The sending of the participation is a success", 'success');
			this.log(res);
			this.pendingParticipationSent = false;
			this.goto('confirmation');
		},
		/* ERROR FOR "POST PARTICIPATION" API CALL */
		participationFailed(error){
			this.log("participationFailed(error)", "function");
			this.log("The sending of the participation is a failure", 'alert');

			let title = error.response.data.message;
			let details;

			if(!!error.response.data.code && (error.response.data.code === "NO_FILE_UPLOAD" || error.response.data.code === "MISSING_FILE_UPLOAD")){
				/* These are known error, with message + notification */				
				if(typeof(error.response.data.notifications) === "object"){
					this.log("notifications is an object");
					details = this.formatKeyedErrorForDisplay(error.response.data.notifications);
					this.log(details);
				}else{
					this.log("notifications is not an object");
					details = error.response.data.notifications;
				}
			}else{
				/* These are unknown error with possible unformated message */
				details = this.formatKeyedErrorForDisplay(error?.response?.data?.errorsKeyed);
			}

			this.displayMessage(title, details, 'alert');
			this.pendingParticipationSent = false;
			this.isClaimCashbackButtonActive = true;
			this.goto('form1');
		},


		updateCouponNumber(data){
		/* how many coupons are availables in this action */
			this.numberOfCouponsInThisAction = data.length;
			this.log(`How many coupons found in this action : ${this.numberOfCouponsInThisAction}`, 'low');
		},

		storeCouponsDetailsForTheForm(data){
			/* here I get all the coupons details and store them in the form usable format */
			/* …so if data change, I must intervene here and not everywhere in the form. */
			/* ! this function could be in a special format mixin file */
			let self = this;
			data.forEach(apiCoupon => {
				this.log(`Treating API coupon with id: ${apiCoupon.id}.`);
				let formCoupon = {};
				formCoupon.currentcounter = apiCoupon.currentcounter;
				formCoupon.description = apiCoupon.description;
				formCoupon.gcn = apiCoupon.gcn;
				formCoupon.id = parseInt(apiCoupon.id);
				formCoupon.idleadmanagement = parseInt(apiCoupon.idleadmanagement);
				formCoupon.maxcounter = parseInt(apiCoupon.maxcounter);
				formCoupon.offersStillAvailableCounter = parseInt(apiCoupon.offersStillAvailableCounter);
				formCoupon.onlineenddate = apiCoupon.onlineenddate;
				this.log(formCoupon);
				self.couponsDetails.push(formCoupon);
				this.log(`formCoupon ${apiCoupon.id} stored`);
			});
			this.log(this.couponsDetails);
		},

/* ++++ COUPONS DETAILS OBTAINED ++++ */
		whatIfCouponsDetailsAreObtained(data){
			this.log("Coupons details obtained", 'green');
			this.updateCouponNumber(data);
			if(this.numberOfCouponsInThisAction > 0){
				this.log("Storing coupons details in the form format", 'low');
				this.storeCouponsDetailsForTheForm(data);
			}
		},
/* ---- COUPONS DETAILS NOT OBTAINED ---- */
		whatIfCouponsDetailsAreNotObtained(error){
			this.log(error, 'alert');
			this.log("Coupons details not obtained", 'red');
		},




		/* FORM MUST CREATE A PARTICIPATION OWNER */
		createParticipationOwner() {
			this.log("createParticipationOwner", 'function');
			let data = {
				"idLeadManagement": config.idlead,
				"email" : this.fieldsValues.email,
				"idLanguageCode": this.getIdOfLanguage(),
			};
			this.log(data);
			this.postToAxios(this.callConfigs.postParticipationOwner, data);
		},

		/* SUCCESS CALLBACK FOR "POST PARTICIPATION OWNER" API CALL */
		successInCreateParticipationOwner(res) {
			this.log(`I managed to create the participationOwner ${res.data.participationOwner.id}`,'success');
			this.ownerData.id = res.data.participationOwner.id;
			this.sendParticipation();
		},

		/* ERROR CALLBACK FOR "POST PARTICIPATION OWNER" API CALL */
		failedInCreateParticipationOwner(error) {
			this.log("ParticipationOwner was not created", 'alert');
			this.log(error.response);
			if(error.response.data.code === "PARTICIPATED"){
				/* User has already participated with these identifiers */
				let title = error.response.data.message;
				let details = error.response.data.notifications;
				this.displayMessage(title, details, 'alert');
			}else{
				let title = error.response.data.message;
				let details = this.formatKeyedErrorForDisplay(error?.response?.data?.errorsKeyed);
				this.displayMessage(title, details, 'alert');
			}
			this.pendingParticipationSent = false;
		},

		/* SUCCESS CALLBACK FOR "GET THE LEAD" API CALL */
		whatIfIGetTheLead(data){
			/* As I receive the data, I set a marker */
			this.leadDataAreReceived = true;
			/* …then I go my business as usual. */
			this.lead = data;
			
			/* TODO must check that the lead is a legit one */
			/* Else, redirect to pageError */

			this.log(`The HDREF is : ${this.lead.referencehd} | The ID is : ${this.lead.id}`, "info");
			
			/* Look if registration is required or not */
			this.talkingAboutRegistration();

			this.metaIds = this.getMetaIds(); /* ! Could be renamed : it gets fields, not only meta */
			this.actionFullDates = this.getFullDates();

			/* Get products list */
			this.getFromAxios(this.callConfigs.getProductsIds);

			/* Get retailer list (if needed) */
			/* TODO add a check if a retailer field is required */
			this.getFromAxios(this.callConfigs.getRetailers);
			
			if (this.registrationRequired){
				this.updateWhatsappCodes();
			}
			this.displayAvailableParticipations();
			this.displayDatesInConsole();
			this.checkIfAccessToFormIsPossible();
		},

		/* If "registration" is a key, what is it's value */
		talkingAboutRegistration(){
			if("registration" in this.lead){
				this.log('There is a "registration" key in the lead', 'low');
				this.registrationRequired = this.isRegistrationRequired(); 
			}else{
				/* For unknow reason, there is no registration in this lead */
				this.log('There was NO "registration" key in the lead', 'alert');
			}
		},

		/* CHECK IF REGISTRATION KEY IS 1 OR 0 */
		isRegistrationRequired(){		
			let	regReq = this.lead.registration === "1";
			let not = regReq?'':'not ';
			this.log(`For this action, a registration is ${not}required.`, 'info');
			return regReq;
		},

		/* ERROR CALLBACK FOR "GET THE LEAD" API CALL */
		whatIfIDontGetTheLead(error){
			this.goto('pageError');
			this.log(error.response?.data?.errors, 'alert');
			let def = "The LEAD cannot be retrieved from API";
			this.displayErrorWith(error, def);
		},

		/* SUCCESS CALLBACK FOR "GET RETAILERS" API CALL */
		whatIfIGetTheRetailersList(data){
			/* ? WHAT IF THERE IS NO RETAILERS (empty list)? */
			/* => Array is empty, the condition in the field is never true, so no other field appears */
			/* ? But does THIS code produce an blocking error? */
			/* The request give me the list of retailer. I can then extract the freefield IDs */
			let listFreeFields = data.filter(item => {
				return item.freefield === "1" || item.freefield === 1;
			});
			listFreeFields.forEach(item => {
				this.unlockFreeRetailersIDs.push(item.id);
			});
			this.log(`I got the list of the "Other" retailers`, 'info');
			this.log(this.unlockFreeRetailersIDs);
		},

		/* ERROR CALLBACK FOR "GET RETAILERS" API CALL */
		whatIfIDontGetTheRetailersList(error){
			this.log(error, 'alert');
			let def = "The RETAILER LIST cannot be retrieved from API";
			this.displayErrorWith(error, def);
		},

		/* SUCCESS CALLBACK FOR "GET PRODUCTS IDS" API CALL */
		whatIfIFoundProductsIds(data){
			this.popitemlist = [...data];
		},

		/* ERROR CALLBACK FOR "GET PRODUCTS IDS" API CALL */
		/* ? Is this the right redirection? */
		whatIfICantFoundProductId(error){			
			this.goto('pageError'); 
			let def = "The products code could not be found";
			this.displayErrorWith(error, def);
		},

		/* SUCCESS CALLBACK FOR "GET LOCALIZING DATA" API CALL */
		localizingDataFound(data){
			/* Storing the data for futur usage */
			this.localizingData = data;
			/* setting the marker to allow the display of the form */
			this.localizingDataAreReceived = true;
			/* What is interesting in this function */
			this.langswitchsThatMustBePresent = [];
			
			/* Display more things */
			this.log("Localizing data", 'group');
			this.log("What I'm expecting as lang buttons: ", 'info');
			for (let item in data){
				/* Summary in the form: [info] BE 1 | FR 1*/
				this.log(`${data[item].countrycodereference.code} ${data[item].countrycodereference.id} | ${data[item].languagecodereference.code} ${data[item].languagecodereference.id}`);
				/* Put them in a list */
				this.langswitchsThatMustBePresent.push(`${data[item].languagecodereference.code}_${data[item].countrycodereference.code}`);
			}	
			/* …store the list */
			this.$store.dispatch('setLangList', this.langswitchsThatMustBePresent);

			this.log(this.langswitchsThatMustBePresent);
			this.log('', 'groupEnd');		

			this.getIdOfLanguage(); // WIP a effacer
		},

		/* ERROR CALLBACK FOR "GET LOCALIZING DATA" API CALL */
		localizingDataNotFound(error){
			this.log(error, 'alert');
		},


		/* RETURNS THE ID BASED ON CURRENT LOCALE */
		getIdOfLanguage(){
			/* This is used to complete participationOwner language */
			/* Default language is NL */
			// ! (still hardcoded as I don't receive any default value)
			let defaultLangId = 2;
			// Check what is in use in the current display
			let currentLocale = this.$i18n.locale.slice(0,2).toUpperCase(); // Returns FR, NL…
			// Get the exact ID of this currentLocale
			let refData = this.localizingData.filter(line => line.languagecodereference.code === currentLocale);
			let finalId = defaultLangId;
			finalId = refData[0].languagecodereference.id; // Maybe there are many results, but language is the same, so I only need the 0th value.
			this.log(`The ID for the current locale "${currentLocale}" is ${finalId}`, 'low');
			return finalId;
		},


		whatIfIGetTheParticipationOwnerData(data){
			/* SUCCES ACTIONS IS THE SAME AS WITH SIMPLE OWNER DATA*/
			this.whatIfIGetTheOwnerData(data);
		},
		whatIfIDontGetTheParticipationOwnerData(error){
			/* THIS SHOULD DISPLAY ANOTHER ERROR MESSAGE */
			this.goto('wrongcode2');
			let def = "The code is wrong or unidentified";
			this.displayErrorWith(error, def);
		},

		ownerIsLegitToParticipateHere(data){
			/* Owner is identified as a participant of this action */
			this.setOwnerData(data);
			this.displayOwnerDataInConsole();
			this.setLang(this.ownerData.idlanguagecode);
			if ( this.reparticipation ){
				this.checkReparticipationDates();
			}
			if( !this.reparticipation ){
				this.checkIfMaxParticipationReached();
			}
		},

		/* SUCCESS CALLBACK FOR "GET PARTICIPATION OWNER DATA" API CALL */
		whatIfIGetTheOwnerData(data){
			/* Check if the lead of the owner and the lead of this action are the same */
			/* So we cannot fill a form of another action! */

			let idFromUserData = data.idLeadManagement ? data.idLeadManagement : data.idleadmanagement;

			this.log(`id lead: ${idFromUserData}, id owner: ${this.lead.id}`, 'wip');
			/* ! They should be both Stringified for comparaison */
			if("" + idFromUserData === "" + this.lead.id){
				this.ownerIsLegitToParticipateHere(data);
			}else{
				this.log("IDs of owner and action are differents", "alert");
				this.whatIfIDontGetTheOwnerData();
			}
		},

		/* ERROR CALLBACK FOR "GET PARTICIPATION OWNER DATA" API CALL */
		whatIfIDontGetTheOwnerData(error){
			this.goto('wrongcode');
			let def = "The code is unidentified or wrong";
			this.displayErrorWith(error, def);
		},


		/* ADD META FOR NON-INDEXABLE PAGES, FOR ROBOTS */
		/* INFO the goto() function trigger this. */
		checkForNonIndexablesPagesForThis(dest){
			let notIndexablePages = ['wrongcode', 'maintenance', 'confirmRegistration', 'confirmation', 'maxReached', 'demo', 'loading', 'pageError'];
			if (notIndexablePages.includes(dest)){
				this.addRobotsNoIndexMeta(); 
			}else{
				this.removeRobotsNoIndexMeta();
			}
		},
		/* REMOVE META "ROBOTS" */
		removeRobotsNoIndexMeta(){
			document.querySelector("[name='robots']")?.remove();
			this.log('Meta "Robot" is absent or removed', 'low');
		},
		/* ADD META "ROBOTS" */
		addRobotsNoIndexMeta(){
			const metaRobots = document.createElement('meta');
			metaRobots.name = 'robots';
			metaRobots.content = 'noindex';
			document.head.appendChild(metaRobots);
			this.log('meta "Robot" had been added to the page', 'low');
		},

		getDataGathered(){
			return this.leadDataAreReceived && this.localizingDataAreReceived && this.metaDataAreReceived;
		},

		maybeAllowTheForm(functionName='N/A'){
			this.log(`This data has changed: ${functionName}`, "low");
			if(this.getDataGathered()){
				this.log("All required data for the form are received", 'success');
				this.log("Allowing the form to be displayed", 'low');
				this.allowFormToBeDisplayed = true;
			}
		},

	},

	watch: {
		/* INFO: If one of these three data change, there is a check, and if all three values are true, allow access to form */
		leadDataAreReceived(){
			this.maybeAllowTheForm('leadDataAreReceived');
		}, 
		localizingDataAreReceived(){
			this.maybeAllowTheForm('localizingDataAreReceived');
		},
		metaDataAreReceived(){
			this.maybeAllowTheForm('metaDataAreReceived');
		},
	}

}

