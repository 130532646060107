<template>
	<div class="aCustomInput field">

		<label  :for="fieldname"><span v-html="$t('inputsTexts.' + translationSource + '.label')"></span><span v-if="isRequired" class="req">&nbsp;*</span></label>

		<input
		:id="fieldname"
		:type="inputType"
		v-model="modelValueComputed"
		:placeholder="$t('inputsTexts.' + translationSource + '.placeholder')"
		:disabled="isDisabled"
		:class="{hasError: isInError, hasSuccess: isInSuccess, isLoading: isLoading}"
		autocapitalize="off" 
		spellcheck="false" 
		autocorrect="off"
		tabindex="0"
		>
		<div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
		<div class="helper" v-html="$t('inputsTexts.' + translationSource + '.helper')"></div>
	</div>
</template>

<!-- ========================================= -->

<script>
// Load only a part of lodash to keep the application small
	import { debounce } from "lodash";
	import FormFields from "../mixins/FormFields";
	import dayjs from "dayjs";
	import CustomLog from "../mixins/CustomLog";
	var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
	dayjs.extend(isSameOrBefore)
	export default {
		name: "eanInput",

		emit: ["update:modelValue", "update:valueHasChanged", "runCheck"],

		mixins: [FormFields, CustomLog],

		props: {
			inputType:{
				type: String,
				validator : function(value){
					return ["text","password","email","hidden","number","search","tel","url", "date"].includes(value);
				}
			},
			isDisabled : {
				type: Boolean,
				required: false,
			default: false
			},
			valueHasChanged: {
				type: String,
				required: false
			},
			dataName: {
				type: String,
				required: false,
			},
		},

		data(){
			return {
				errorMessage : "",
			}
		},

		computed: {
			modelValueComputed: {
				get() {
					return this.modelValue;
				},
				set(newVal) {
					this.validateMyselfDebounced();
					this.$emit('update:modelValue', newVal);
				}
			}
		},

		methods : {


			runApiCall(){
				let data = {[this.dataName]: this.modelValueComputed};
				/* If GTIN, the route is a bit different */
				let url = this.dataName === 'gtin' ? `/gtin/validate`: `/lead/${this.idLead}/data/${this.fieldname}/validate`;

				this.axios.post( url, data )
				.then(res => {
					this.log(res);
					this.giveSuccess();
					this.sendValidityOfTheFieldToTheForm(true);
					this.$emit('runCheck', {fieldname: this.fieldname, valid: true});
					this.setNotLoading();
				})
				.catch(error => {
					this.setNotLoading();
					this.log("ERROR", "error");
					this.log(error);
					console.log(`CustomInput reported an error: ${error.response?.data?.message}`);
					if(error.response?.status === 503){
						this.setNotLoading();
						this.$store.dispatch('setMaintenance', true);
					}else{
						// TODO better error management
						// default error : this.errorMessage = this.$t('inputsTexts.dropdown.errorData');
						console.log("--> " + error.response?.data?.errors[this.dataName][0]);
						this.errorMessage = error.response?.data?.errors[this.dataName][0];
						this.setNotLoading();
						this.giveError();
						this.sendValidityOfTheFieldToTheForm(false);
					}

				})
				.then(()=>{});

			},

			gtinCheck(){
				// gtin is well-formed :
				if(this.lengthOfInput === 13){
					this.runApiCall();
				}else{
					this.setNotLoading();
					if(this.lengthOfInput === 0){
						this.removeSuccessOrError();
					}
					if(this.lengthOfInput > 0){
						this.errorMessage = this.$t('inputsTexts.dropdown.errorLength');
						this.giveError();
					}
					this.sendValidityOfTheFieldToTheForm(false);
				}
			},

			validateMyself() {
				console.group("CustomInput Validation " + this.fieldname);
				console.log(`I use ${this.fieldname} as key and ${this.modelValueComputed} as value`);
				this.log(`dataName: ${this.dataName}`, 'wip');
				this.displayedInTheField = this.modelValueComputed;
				this.setLoading();
				console.groupEnd();
				if(this.inputType === "date") {
					this.setNotLoading();
					return this.dateCheck()
				}
				if(this.dataName === 'gtin'){
					this.gtinCheck();
				}else{
					this.runApiCall();
				}
			},

			sendValidityOfTheFieldToTheForm(bool){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},


	/* TODO : add a check: is date inbetween purchase dates from lead */
	/* ! THERE IS A PROBLEM : 2022/12/05 and 2022/05/12 are not the same ! */
	/* use then this.$t('errors.dateOutOfPeriod'); */
			dateCheck() {
				this.dateMessage = null;
				let date = dayjs(this.modelValueComputed);
				if(!date.isValid()) {
					this.errorMessage = this.$t('errors.dateInvalid');
					this.sendValidityOfTheFieldToTheForm(false);
				} else if(!date.isSameOrBefore(dayjs())) {
					this.errorMessage = this.$t('errors.dateBefore');
					this.giveError();
					this.sendValidityOfTheFieldToTheForm(false);
				} else {
					this.giveSuccess();
					this.sendValidityOfTheFieldToTheForm(true);
				}
			},
		},

		watch: {
			modelValue(){
				this.validateMyselfDebounced();
			},
		},

		created() {
			this.validateMyselfDebounced = debounce(this.validateMyself, 500);
	// run validation immediately when the value is not null or empty
			if(this.modelValueComputed) {
				this.validateMyselfDebounced();
			}
		}
	}

</script>

<!-- ========================================= -->

<style lang="scss" scoped>
</style>
